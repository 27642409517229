import {Container,Row, Col} from 'react-bootstrap'
import React from "react"
import "./pageart.scss"
import "./pageart-mobile.scss"
const Pageart = ({ content, content2, altText }) => (

  <div className="pageart" role="img" aria-label={altText}>

  	{content &&

	  	<Container>
	  		<Row>
	  			<Col><h1>
	  				<span>{content}</span>
	  				<br className="d-lg-none" />
	  				<span>{content2}</span>
	  			</h1></Col>
	  		</Row>
	  	</Container>

  	}

  </div>

)

export default Pageart
