import {Container,Row, Col,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import Pageart from "../components/pageart/pageart"
import ModalOverlay from "../components/modal/modal.js"
import PlayButton from "../images/playbutton.svg"
import SEO from "../components/seo"
import {Link} from "gatsby"

const Podcast = () => (

  <Layout>

    <div className="podcast">

      <SEO title="TTYH Podcast" 
           description="Listen to podcasts about cardiovascular disease risk. Listen to the experts and learn about preventative measures you can take to reduce the risk of heart attack and stroke."
      />

      <Pageart content="TTYH PODCAST:" content2="DON'T DROP THE BALL ON CV RISK"></Pageart>

      <Container fluid className="page-container">
        <Row>

          <Col className="content-wrapper">

            <p>You’re busy. But, hopefully, never too busy to learn new lessons about cardiovascular disease, including the risk of a heart attack or stroke. Renowned television commentator and former NFL quarterback Ron Jaworski sits down with leading health experts to help guide you through a new way of looking at your cardiovascular (CV) health. In the episodes below, you’ll learn about the different preventive measures that you can take to lower your CV risk, including the risk of heart attack and stroke.</p>


            <div className="text-center mt-40">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/">
                  <span class="btn btn-primary text-decoration-none">Listen to the Podcasts</span>
              </ModalOverlay>
            </div>
            
            {/* start podcasts */}
            

           


                <div className="podcast-callout">
                  <ModalOverlay
                    message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                    buttontext = "Continue"
                    link = "https://true-to-your-heart.simplecast.com/episodes/change-your-life-for-american-heart-month"
                    ><img id="PODCAST_AMERICAN_HEART_MONTH" src={PlayButton} className="mr-20" /></ModalOverlay>
                  <div>Change Your Life for "American Heart Month"</div>
                </div>
                <p className="ml-80">Ron Jaworski, Dr. Brett Nowlan, and Dr. Andrea Baer discuss top indicators of disease and powerful lifestyle changes to improve your heart health.</p>
                <p className="episode">
                  <span>FEBRUARY 16, 2022</span> | <span>27:43</span> | <span>S1:E7</span>
                </p>
                <div className="podcast-callout">
                  <ModalOverlay
                    message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                    buttontext = "Continue"
                    link = "https://true-to-your-heart.simplecast.com/episodes/diabetes-and-heart-health"
                    ><img id="PODCAST_DIABETES_AND_HEART_HEALTH" src={PlayButton} className="mr-20" /></ModalOverlay>
                  <div>Diabetes and Heart Health</div>
                </div>
                <p className="ml-80">Dr. Steve Edelman and Dr. Schafer Boeder join Ron to discuss how diabetes can impact your heart health and give expert advice for addressing both.</p>
                <p className="episode">
                  <span>NOVEMBER 30, 2021</span> | <span>36:55</span> | <span>S1:E6</span>
                </p>

            <div className="podcast-callout">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/episodes/harris-survey-results-and-heart-health"
                ><img id="PODCAST_HARRIS_SURVEY _RESULTS_AND_HEART_HEALTH" src={PlayButton} className="mr-20" /></ModalOverlay>
              <div>Harris Survey Results and Heart Health</div>
            </div>
            <p className="ml-80">This week on True To Your Heart, brought to you by Amarin, join Ron Jaworski as he sits down to speak with Managing Director of Harris Surveys, Rob Jekielek, as well as Dr. Dean Bramlet, the Medical Director of the Cardiovascular Diagnostic Center of the Heart and Lipid Institute of Florida in St. Petersburg.</p>
            <p className="episode">
              <span>OCTOBER 28, 2021</span> | <span>30:32</span> | <span>S1:E5</span>
            </p>

            <div className="podcast-callout">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/episodes/underserved-populations-and-heart-health"
                ><img id="PODCAST_UNDERSERVED_POPULATIONS_AND_HEART_HEALTH" src={PlayButton} className="mr-20" /></ModalOverlay>
              <div>Underserved Populations and Heart Health</div>
            </div>
            <p className="ml-80">Ron Jaworski gets a chance to sit down with Dr. Kenton Forte, an Assistant Clinical Professor at SUNY in Buffalo, who has been practicing internal medicine at the Heart Beat Center of Western New York for over 22 years. Together they talk about how different social determinants such as education, housing, stress, and more can impact your overall health as a person.</p>
            <p className="episode">
              <span>SEPTEMBER 15, 2021</span> | <span>36:18</span> | <span>S1:E4</span>
            </p>

            <div className="podcast-callout">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/episodes/breaking-the-stigma-of-visiting-your-physician"
                ><img id="PODCAST_BREAKING_THE_STIGMA_OF_VISITING_YOUR_PHYSICIAN" src={PlayButton} className="mr-20" /></ModalOverlay>
              <div>Breaking the Stigma of Visiting Your Physician</div>
            </div>
            <p className="ml-80">ESPN Analyst Merril Hoge joined Ron to discuss how surviving open-heart surgery and Non-Hodgkin's Lymphoma had an impact on his health journey. Ron also sat down with Dr. Peter Toth, the Director of Preventive Cardiology at the CGH medical center in Sterling, Illinois, and talked about the importance of not just preventive care, but early preventive care when it comes to men and their heart health.</p>
            <p className="episode">
              <span>JUNE 22, 2021</span> | <span>36:44</span> | <span>S1:E3</span>
            </p>

            <div className="podcast-callout">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/episodes/women-and-heart-health"
                ><img id="PODCAST_WOMEN_AND_HEART_HEALTH" src={PlayButton} className="mr-20" /></ModalOverlay>
              <div>Women and Heart Health</div>
            </div>
            <p className="ml-80">This week we are focusing on women and their heart health with Dr. Payal Kohli, a noninvasive cardiologist and founding partner of Cherry Creek Heart in Denver. Dr. Kohli explains to us how heart disease affects women differently, and how cardiovascular health is just as important for women as it is for men.</p>
            <p className="episode">
              <span>JUNE 1, 2021 </span> | <span>26:23</span> | <span>S1:E2</span>
            </p>

            <div className="podcast-callout">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com"]}
                buttontext = "Continue"
                link = "https://true-to-your-heart.simplecast.com/episodes/taking-charge-of-your-health"
                ><img id="PODCAST_TAKING_CHARGE_OF_YOUR_HEALTH" src={PlayButton} className="mr-20" /></ModalOverlay>
              <div>Taking Charge of Your Health</div>
            </div>  
            <p className="ml-80">Ron Jaworski is joined by Dr. Dean G. Karalis and TV Health Expert Joy Bauer to discuss the importance of making your heart health a priority again. As an expert in lipidology and cardiology, Dr. Karalis has seen firsthand the effects the pandemic has had on cardiovascular health in the US. He shares with Ron the advice he gives to patients so that they can re-take charge of their health journey.</p>
            <p className="episode">
              <span>MAY 12, 2021</span> | <span>34:33</span> | <span>S1:E1</span>
            </p>

            <hr className="grey" />

            <p className="louder pt-5">
              <strong className="black">TALK TO YOUR DOCTOR TODAY</strong> ABOUT SWITCHING TO FDA-APPROVED THERAPIES, AS YOU MAY STILL BE AT RISK OF HAVING A HEART ATTACK OR STROKE.
            </p>

            <p className="text-center">
              <ModalOverlay
                message = {[<strong>You are now leaving</strong>,<br/>,"TruetoYourHeart.com",<br/>,<p className="mobile-text-300">You will be directed to another Amarin website to learn about a prescription cardiovascular treatment.</p>]}
                buttontext = "Continue"
                link = "https://vascepa.com/"
                ><span id="PODCASTS_CTA_LEARN_MORE" class="btn btn-primary text-decoration-none">LEARN MORE</span></ModalOverlay></p>

          </Col>
        </Row>
      </Container>

    </div>

  </Layout>

)

export default Podcast
